import 'jspdf-autotable'
import { jsPDF } from "jspdf"
const _ = require('lodash')
import Loading from '@/components/widgets/Loading.vue'

export default {
  data() {
    return {
      loading: false,
      customer: 'เกริกวิทย์ ทำของดี',
      location: '94/43 หมู่ 4 เศรษฐกิจ 1-บางปลา ต.บ้านเกาะ อ.เมือง จ.สมุทรสาคร',
      shipping_method: 'บริษัทจัดส่ง',
      document: {
        no: 'INV-202216554654',
        date: '25/09/2019',
        duedate: '25/09/2019',
        seller: 'Administrator',
        printTime: '15:32:13'
      },
      products: {
        id: '61837be876a431b5936932ba',
        uuid: '61837be876a431b5936932ba',          
        code: '2001-0002',
        name: '0.30 น้ำตาล',
        saller_code: '001',
        material: 45.00,
        remaining_length: 647.18,
        remaining_weight: 1203.75,
        product_detail: [
          {
            id: '61837be876a431b5936932bf',
            uuid: '61837be876a431b5936932bf',  
            code: '2001-0002',
            name: 'แผ่นตรง 0.30 น้ำตาล',
            work_type: 1,
            work_title: 'งานแผ่น',
            length: 2.00,
            qty: '20 แผ่น'
          },
          {
            id: '61837be876a431b5936932bd',
            uuid: '61837be876a431b5936932by',  
            code: '2001-0002',
            name: 'แผ่นตรง 0.30 3x3 น้ำตาล',
            work_type: 2,
            work_title: 'งานครอบ',
            length: 2.50,
            qty: '20 แผ่น'
          }
        ]
      },
      items: [],
      note: 'ทดสอบ',
      nettotal: 0
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.onInitTableData()
    })
  },
  components: {
    Loading
  }, 
  methods: {
    async onInitIFrame() {
      this.loading = true
      setTimeout(async () => {
        const doc = new jsPDF('p','mm','a4')
        await this.onInitDocument(doc)
        let element = document.getElementById('production')
        if (element) {
          element.setAttribute("src", doc.output('bloburl'))
          setTimeout(() => {
            this.loading = false
            this.onIframePrint()
          }, 1500)
        }
      }, 1000)
    },
    onInitTableData() {
      for (let index = 0; index < this.products.product_detail.length; index++) {
        const element = this.products.product_detail[index]
        if (this.items.length === 0) {
          this.items.push({
            work_type: element.work_type,
            work_title: element.work_title,
            data: [element]
          })
        } else {
          const f = _.find(this.items, { work_type: element.work_type })
          if (f) {
            f.data.push(element)
          } else {
            this.items.push({
              work_type: element.work_type,
              work_title: element.work_title,
              data: [element]
            })
          }
        }
      }
    },
    onInitDocument(doc) {
        return new Promise(async(resolve, reject) => {
        try {
          doc.addFileToVFS(
            'THSarabunNew.ttf',
            // ttf font file converted to base64
            // following is Consolas with only hex digit glyphs defined (0-9, A-F)
            this.thSarabunNormal
          )
          // add custom font to file
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal')
          doc.setFont('THSarabunNew', 'normal')

          doc.addFileToVFS(
            'THSarabunNew Bold.ttf',
            // ttf font file converted to base64
            // following is Consolas with only hex digit glyphs defined (0-9, A-F)
            this.thSarabunBold
          )
          // add custom font to file
          doc.addFont('THSarabunNew Bold.ttf', 'THSarabunNew', 'bold')
          doc.setFont('THSarabunNew', 'bold')

          const head = await this.onInitHeader(doc)
          doc.autoTable({
            theme: 'plain',
            margin: { top: 8, bottom: 0, left: 8, right: 8 },
            styles: {
              font: 'THSarabunNew'
            },
            headStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            bodyStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            footStyles: {
              valign: 'top',
              halign: 'center',
              minCellHeight: 0
            },
            showFoot: 'lastPage',
            head: head,
            body: []
          })

          const subHeader = await this.onInitSubHeader(doc)
          doc.autoTable({
            theme: 'plain',
            margin: { top: 0, bottom: 0, left: 8, right: 8 },
            startY: doc.lastAutoTable.finalY,
            styles: {
              font: 'THSarabunNew'
            },
            headStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            bodyStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            footStyles: {
              valign: 'top',
              halign: 'center',
              minCellHeight: 0
            },
            showFoot: 'lastPage',
            head: [],
            body: subHeader
          })

          const body = await this.onInitBody(doc)
          doc.autoTable({
            theme: 'plain',
            margin: { top: 8, bottom: 0, left: 8, right: 8 },
            startY: doc.lastAutoTable.finalY,
            styles: {
              font: 'THSarabunNew'
            },
            headStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            bodyStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            footStyles: {
              valign: 'top',
              halign: 'center',
              minCellHeight: 0
            },
            showFoot: 'lastPage',
            head: [],
            body: body,
            didDrawCell (data) {
              if (data.cell.raw && data.section === 'body') {
                if (data.cell.raw.content === 'inspector' ||
                data.cell.raw.content === 'approver') {
                  doc.setLineWidth(0.3);
                  doc.rect(data.cell.x + 8, data.cell.y + 2, 4, 4, 'S');
                }
              }
            }
          })
          
          const sub_footer = await this.onInitSubFooter(doc)
          doc.autoTable({
            theme: 'plain',
            margin: { top: 8, bottom: 8, left: 8, right: 8 },
            startY: doc.lastAutoTable.finalY,
            styles: {
              font: 'THSarabunNew'
            },
            headStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            bodyStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            footStyles: {
              valign: 'top',
              halign: 'center',
              minCellHeight: 0
            },
            showFoot: 'lastPage',
            foot: sub_footer
          })

          const footer = await this.onInitFooter(doc)
          doc.autoTable({
            theme: 'plain',
            margin: { top: 8, bottom: 8, left: 8, right: 8 },
            styles: {
              font: 'THSarabunNew'
            },
            headStyles: { },
            bodyStyles: {
              valign: 'top',
              minCellHeight: 0
            },
            footStyles: {
              valign: 'bottom',
              halign: 'center',
              minCellHeight: 0
            },
            showFoot: 'lastPage',
            foot: footer,
            willDrawCell (data) {
              if (data.cell.raw && data.section === 'foot') {
                if (data.cell.raw.content === 'issuer') {
                  data.doc.setLineWidth(0.25)
                  data.doc.setDrawColor(15, 23, 42)
                  data.doc.line(data.cell.x + 5, data.cell.y + 14, data.cell.x + 60, data.cell.y + 14)
                }
                if (data.cell.raw.content === 'approved') {
                  data.doc.setLineWidth(0.25)
                  data.doc.setDrawColor(15, 23, 42)
                  data.doc.line(data.cell.x + 5, data.cell.y + 14, data.cell.x + 60, data.cell.y + 14)
                }
                if (data.cell.raw.content === 'accepted' ) {
                  data.doc.setLineWidth(0.25)
                  data.doc.setDrawColor(15, 23, 42)
                  data.doc.line(data.cell.x + 5, data.cell.y + 14, data.cell.x + 60, data.cell.y + 14)
                }
              }
            }
          })
          resolve(doc)
        } catch (error) {
          reject(error)
        }
      })
    },
    onInitHeader(doc) {
      return new Promise(async (resolve, reject) => {
        const width = doc.internal.pageSize.getWidth() - 18
        const portion = width / 9
        const head = [
          [
            {
              content: 'ใบสั่งผลิต',
              colSpan: 9,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 26,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'bold',
                halign: 'left',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: `ลูกค้า ${this.customer}`,
              colSpan: 6,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: 'ฝ่ายผลิต',
              colSpan: 3,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 16,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'bold',
                halign: 'left',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: '',
              colSpan: 6,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: 'เลขที่เอกสาร:',
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: this.document.no,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: '',
              colSpan: 6,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: 'เวลา/วันที่ออกบิล:',
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: this.document.date,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: '',
              colSpan: 6,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: 'รับของ:',
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: this.document.duedate,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: `สถานที่ส่ง: ${this.location}`,
              colSpan: 6,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: 'พนักงานขาย:',
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: this.document.seller,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: `การจัดส่ง: ${this.shipping_method} `,
              colSpan: 6,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: {
                  bottom: 2
                },
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: 'เวลาพิมพ์:',
              colSpan: 1,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: {
                  bottom: 2
                },
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: this.document.printTime,
              colSpan: 2,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: {
                  bottom: 2
                },
                fontStyle: 'normal',
                halign: 'right',
                valign: 'middle'
              }
            }
          ],
          [
            {
              content: 'ลำดับ',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: portion / 1.4,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: 'รายการละเอียด',
              colSpan: 4,
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: 'ยาว(ม.)',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: portion,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: 'จำนวน',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: portion + 5,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: 'ผู้ผลิต',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: portion,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: 'ผู้ตรวจ',
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: portion,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            }
          ]
        ]
        resolve(head)
      })
    },
    onInitSubHeader(doc) {
      return new Promise(async (resolve, reject) => {
        const width = doc.internal.pageSize.getWidth() - 18
        const portion = width / 9
        const head = [
          [
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion / 1.4,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: `รหัสม้วนคอยล์ ${this.products.code}`,
              colSpan: 2,
              styles: {
                lineWidth: {
                  left: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: {
                  top: 2,
                  left: 2
                },
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'left',
                valign: 'top'
              }
            },
            {
              content: '',
              colSpan: 2,
              styles: {
                lineWidth: {
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: {
                  top: 2,
                  left: 2
                },
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'left',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion + 5,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            }
          ],
          [
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion / 1.4,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: `ชื่อม้วนคอยล์ ${this.products.name}`,
              colSpan: 4,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: {
                  left: 2
                },
                cellWidth: 'auto',
                fontSize: 14,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'left',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion + 5,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            }
          ],
          [
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion / 1.4,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: `รหัสผู้ขาย ${this.products.saller_code}`,
              colSpan: 2,
              styles: {
                lineWidth: {
                  left: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: {
                  left: 2
                },
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'left',
                valign: 'top'
              }
            },
            {
              content: `ใช้วัตถุดิบ(m) ${this.products.material}`,
              colSpan: 2,
              styles: {
                lineWidth: {
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: {
                  left: 2
                },
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'left',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  right: 0.1,
                  left: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'left',
                valign: 'top'
              }
            },
            {
              content: '',
              colSpan: 1,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion + 5,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            }
          ],
          [
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion / 1.4,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: `ความยาวคงเหลือ(m.) ${this.products.remaining_length}`,
              colSpan: 4,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: {
                  left: 2
                },
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'left',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion + 5,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'left',
                valign: 'top'
              }
            }
          ],
          [
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion / 1.4,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: `น้ำหนักคงเหลือ(กก) หลังผลิต ${this.products.remaining_weight}`,
              colSpan: 4,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: {
                  left: 2,
                  bottom: 2
                },
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'left',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion + 5,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: '',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: 0,
                cellWidth: portion,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            }
          ],
        ]
        resolve(head)
      })
    },
    onInitBody(doc) {
      return new Promise(async (resolve, reject) => {
        const width = doc.internal.pageSize.getWidth() - 18
        const portion = width / 9
        const data = this.items          
        const body = []
        for (let index = 0; index < data.length; index++) {
        const element = data[index]
        let obj = {}
        let group = {}
        group = [
          {
            content: '',
            styles: {
              cellWidth: portion / 1.4,
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              fontSize: 15,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              fontStyle: 'normal',
              halign: 'center',
              valign: 'middle'
            }
          },
          {
            content: element.work_title,
            colSpan: 4,
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              fillColor: [252, 238, 131],
              cellPadding: {
                left: 2,
                right: 2
              },
              cellWidth: 'auto',
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'left',
              valign: 'middle'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              fillColor: [255, 255, 255],
              cellPadding: 0,
              cellWidth: portion,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              fillColor: [255, 255, 255],
              cellPadding: 0,
              cellWidth: portion + 5,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              fillColor: [255, 255, 255],
              cellPadding: 0,
              cellWidth: portion,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              fillColor: [255, 255, 255],
              cellPadding: 0,
              cellWidth: portion,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          }
        ]
        body.push(group)

        for (let i = 0; i < element['data'].length; i++) {
          const item = element['data'][i];
          obj = [
            {
              content: index + 1,
              styles: {
                cellWidth: portion / 1.4,
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fontSize: 15,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                fontStyle: 'normal',
                halign: 'center',
                valign: 'middle'
              }
            },
            {
              content: `${item.code} ${item.name}`,
              colSpan: 4,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                cellWidth: 'auto',
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'left',
                valign: 'middle'
              }
            },
            {
              content: item.length,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                cellWidth: portion,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: item.qty,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                fillColor: [255, 255, 255],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                cellWidth: portion + 5,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: 'normal',
                halign: 'right',
                valign: 'top'
              }
            },
            {
              content: 'inspector',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                cellWidth: portion,
                fontSize: 1,
                fillColor: [255, 255, 255],
                textColor: [255, 255, 255],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            },
            {
              content: 'approver',
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1
                },
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1
                },
                cellWidth: portion,
                fontSize: 1,
                fillColor: [255, 255, 255],
                textColor: [255, 255, 255],
                fontStyle: 'normal',
                halign: 'center',
                valign: 'top'
              }
            }
          ]
          body.push(obj)
          }
        }

        let emptyBody = {}
        emptyBody = [
          {
            content: '',
            styles: {
              cellWidth: portion / 1.4,
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              fontSize: 15,
              fillColor: [255, 255, 255],
              textColor: [255, 255, 255],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              fontStyle: 'normal',
              halign: 'center',
              valign: 'middle'
            }
          },
          {
            content: '',
            colSpan: 4,
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: 'auto',
              fontSize: 15,
              fillColor: [255, 255, 255],
              textColor: [255, 255, 255],
              fontStyle: 'normal',
              halign: 'left',
              valign: 'middle'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: portion,
              fontSize: 15,
              fillColor: [255, 255, 255],
              textColor: [255, 255, 255],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: portion + 5,
              fontSize: 15,
              fillColor: [255, 255, 255],
              textColor: [255, 255, 255],
              fontStyle: 'normal',
              halign: 'right',
              valign: 'top'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: portion,
              fontSize: 1,
              fillColor: [255, 255, 255],
              textColor: [255, 255, 255],
              fontStyle: 'normal',
              halign: 'center',
              valign: 'top'
            }
          },
          {
            content: '',
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1
              },
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1
              },
              cellWidth: portion,
              fontSize: 1,
              fillColor: [255, 255, 255],
              textColor: [255, 255, 255],
              fontStyle: 'normal',
              halign: 'center',
              valign: 'top'
            }
          }
        ]

        if (body.length <= 12) {
          const number = 12 - body.length
          for (let index = 0; index < number; index++) {
            body.push(emptyBody)
          }
        }
        resolve(body)
      })
    },
    onInitFooter(doc) {
      return new Promise((resolve, reject) => {
        const width = doc.internal.pageSize.getWidth() - 20
        const portion = width / 9

        const footer = []
        const signer_line = [
        {
            content: `issuer`,
            colSpan: 3,
            styles: {
            cellWidth: portion * 3,
            fontSize: 15,
            textColor: [255, 255, 255],
            cellPadding: {
              top: 10
            },
            fontStyle: 'normal',
            halign: 'center',
            valign: 'bottom'
            }
        },
        {
            content: `approved`,
            colSpan: 3,
            styles: {
            cellWidth: portion * 3,
            fontSize: 15,
            textColor: [255, 255, 255],
            cellPadding: 0,
            fontStyle: 'normal',
            halign: 'center',
            valign: 'bottom'
            }
        },
        {
            content: `accepted`,
            colSpan: 3,
            styles: {
            cellWidth: 'wrap',
            fontSize: 15,
            textColor: [255, 255, 255],
            cellPadding: 0,
            fontStyle: 'normal',
            halign: 'center',
            valign: 'bottom'
            }
        }
        ]
        footer.push(signer_line)

        const signer_style = {
        cellWidth: 'auto',
        fontSize: 15,
        textColor: [15, 23, 42],
        cellPadding: 1,
        fontStyle: 'normal',
        halign: 'center',
        valign: 'bottom'
        }
        const signer_name = [
          {
            content: 'ผู้อนุมัติซื้อ',
            colSpan: 3,
            styles: signer_style
          },
          {
            content: 'พนักงานขาย',
            colSpan: 3,
            styles: {
              cellWidth: portion * 3,
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: 1,
              fontStyle: 'normal',
              halign: 'center',
              valign: 'bottom'
            }
          },
          {
            content: 'ผู้มีอำนาจลงนาม',
            colSpan: 3,
            styles: signer_style
          }
        ]
        footer.push(signer_name)
        
        const signer_date = [
          {
            content: `( ${ this.$options.filters.getDateShortFormatted(new Date())} )`,
            colSpan: 3,
            styles: signer_style
          },
          {
            content: `( ${ this.$options.filters.getDateShortFormatted(new Date())} )`,
            colSpan: 3,
            styles: {
              cellWidth: portion * 3,
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: 1,
              fontStyle: 'normal',
              halign: 'center',
              valign: 'bottom'
            }
          },
          {
            content: `( ${ this.$options.filters.getDateShortFormatted(new Date())} )`,
            colSpan: 3,
            styles: signer_style
          }
        ]
        footer.push(signer_date)
        resolve(footer)
      })
    },
    onInitSubFooter(doc) {
      return new Promise((resolve, reject) => {        
        const footer = []
        const total = [{
          content: `หมายเหตุ: ${this.note}`,
          colSpan: 9,
          styles: {
            lineWidth: 0.1,
            cellPadding: 2,
            cellWidth: 'auto',
            fontSize: 15,
            textColor: [15, 23, 42],
            fontStyle: 'normal',
            halign: 'left',
            valign: 'top'
            }
          }
        ]
        footer.push(total)
        resolve(footer)
      })
    },
    onIframePrint() {
      let iFrame = document.getElementById("production")
      iFrame.focus()
      iFrame.contentWindow.print()
    }
  }
}
